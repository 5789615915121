<template>
    <v-dialog v-model="isOpen" width="1200">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Créer une disponibilité
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <ValidationObserver ref="observer">
                    <table style="border-spacing: 10px;">
                        <tr>
                            <td>
                                Quel intervenant
                            </td>

                            <td>
                                <AppointmentStakeholderAutocomplete ref="appointmentStakeholderAutocomplete" v-model="stakeholder" noDynamicSearch />
                            </td>
                        </tr>
                    </table>
                </ValidationObserver>

                <div class="d-flex align-center justify-center mb-4">
                    <v-btn @click="goToPreviousWeek()" class="mr-4" small icon>
                        <v-icon> fas fa-chevron-left </v-icon>
                    </v-btn>

                    <span class="subtitle-1"> Semaine du {{ currentStartWeekDay | toDate() }} au {{ currentEndOfWeekDay | toDate() }} </span>

                    <v-btn @click="goToNextWeek()" class="ml-4" small icon>
                        <v-icon> fas fa-chevron-right </v-icon>
                    </v-btn>
                </div>

                <table class="w-100">
                    <thead>
                        <th v-for="(day, i) of weekDays" :key="i" class="text-capitalize">
                            {{ day.date | toDay() }} <br>
                            <span class="font-weight-regular"> {{ day.date | toDate() }} </span>
                        </th>
                    </thead>

                    <tbody>
                        <tr>
                            <td v-for="(day, i) of weekDays" :key="i" style="width: calc(100%/7)" class="text-center">
                                <v-menu v-model="day.isMenuOpen" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs  }">
                                        <v-btn color="primary" x-small v-on="on" v-bind="attrs">
                                            <v-icon x-small> fas fa-plus </v-icon>
                                        </v-btn>

                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn @click="copyDisponibilitiesToNextDay(day)" class="ml-2" style="position: absolute; top" x-small text icon v-on="on">
                                                    <v-icon x-small> fas fa-arrow-right </v-icon>
                                                </v-btn>
                                            </template>
                                            Copier sur le jour suivant
                                        </v-tooltip>
                                    </template>

                                    <v-card>
                                        <v-card-text class="d-flex">
                                            <v-select v-model="day.startHours" :items="selectHours" class="mr-2" label="de" style="width: 100px;" hide-details outlined dense />
                                            <v-select v-model="day.endHours" :items="selectHours" label="à" style="width: 100px;" hide-details outlined dense />
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn @click="addDisponibility(day, day.startHours, day.endHours)" color="primary" x-small depressed>
                                                Ajouter
                                            </v-btn>

                                            <v-btn @click="cancelDisponibility(day)" x-small depressed>
                                                Annuler
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </td>
                        </tr>

                        <tr>
                            <td v-for="(day, i) of weekDays" :key="i" class="text-center">
                                <div v-for="(disponibility, i) of day.disponibilities" :key="i" class="d-flex align-center justify-center">
                                    De {{ disponibility.startHours }} à {{ disponibility.endHours }}

                                    <v-btn @click="removeDisponibility(day, i)" color="red" icon>
                                        <v-icon x-small> fas fa-times </v-icon>
                                    </v-btn>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="copyDisponibilityToNextDay(disponibility, day)" x-small text icon v-on="on">
                                                <v-icon x-small> fas fa-arrow-right </v-icon>
                                            </v-btn>
                                        </template>
                                        Copier sur le jour suivant
                                    </v-tooltip>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" class="mr-2" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue';
import { addHours, addMinutes, addWeeks, startOfWeek, endOfWeek, addDays, isWithinInterval } from 'date-fns';
import AppointmentStakeholderAutocomplete from '../widgets/AppointmentStakeholderAutocomplete.vue';

export default {
    name: 'CreateDisponibilityDialogBatch',

    components: {
        AppointmentStakeholderAutocomplete
    },

    data: () => ({
        day: new Date(),

        isOpen: false,
        stakeholder: null,

        additionalDataFetched: false,

        currentStartWeekDay: startOfWeek(new Date(), { weekStartsOn: 1 }),
        currentEndOfWeekDay: endOfWeek(new Date(), { weekStartsOn: 1 }),
        weekDays: [],

        startDateHours: null,
        endDateHours: null
    }),

    computed: {
        selectHours() {
            const hours = [];

            let startHour = 6;
            let endHour = 20;

            for (let i = startHour; i <= endHour; i++) {
                hours.push(i + ':00');
                hours.push(i + ':30');
            }

            return hours;
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.$nextTick(() => {
                    if (!this.additionalDataFetched) {
                        this.additionalDataFetched = true;
                        this.$refs.appointmentStakeholderAutocomplete.fetchStakeholders({ limit: 10000 });
                    }
                    this.$refs.observer.reset();
                });
            }
        }
    },

    methods: {
        copyDisponibilitiesToNextDay(day) {

            for (const disponibility of day.disponibilities) {
                day.startHours = 
                this.addDisponibility(day.nextDay, disponibility.startHours, disponibility.endHours);
            }
        },

        copyDisponibilityToNextDay(disponibility, day) {
            this.addDisponibility(day.nextDay, disponibility.startHours, disponibility.endHours);
        },

        async submit() {
            try {
                this.setLoading(true);

                if (!this.stakeholder) {
                    this.$notify({
                        title: 'Erreur',
                        text: 'Vous devez selectionner un intervenant',
                        type: 'error'
                    });
                    return;
                }

                const disponibilities = [];
                for (const day of this.weekDays) {
                    for (const dispo of day.disponibilities) {
                        disponibilities.push({
                            startDate: dispo.startDate,
                            endDate: dispo.endDate,
                            stakeholderIds: [this.stakeholder]
                        });
                    }
                }

                if (disponibilities.length === 0) {
                    this.$notify({
                        title: 'Erreur',
                        text: 'Vous devez selectionner au moins un créneau',
                        type: 'error'
                    });
                    return;
                }

                const body = { disponibilities };

                const { success, err } = await this.repos.appointments.createDisponibility(body);
                if (success) {
                    this.isOpen = false;
                    this.stakeholder = null;
                    this.initWeekDays();
                    this.$emit('created', disponibilities);
                    this.$notify({
                        title: 'Information',
                        text: 'Les disponibilité ont bien été créées',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création d\'une disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        addDisponibility(day, startHoursStr, endHoursStr) {
            if (!startHoursStr || !endHoursStr) {
                return;
            }
            const [startHours, startMinutes] = startHoursStr.split(':');
            const [endHours, endMinutes] = endHoursStr.split(':');
            const startDate = addMinutes(addHours(day.date, startHours), startMinutes);
            const endDate = addMinutes(addHours(day.date, endHours), endMinutes);

            for (const disponibility of day.disponibilities) {
                const a = isWithinInterval(startDate, { start: disponibility.startDate, end: disponibility.endDate });
                const b = isWithinInterval(endDate, { start: disponibility.startDate, end: disponibility.endDate });
                if (a || b) {
                    return;
                }
            }

            day.disponibilities.push({
                startDate,
                endDate,
                startHours: startHoursStr,
                endHours: endHoursStr
            });
            Vue.set(day, 'isMenuOpen', false);
            day.startHours = undefined;
            day.endHours = undefined;
            this.$forceUpdate();
        },

        cancelDisponibility(day) {
            Vue.set(day, 'isMenuOpen', false);
            day.startHours = undefined;
            day.endHours = undefined;
            this.$forceUpdate();
        },

        removeDisponibility(day, index) {
            day.disponibilities.splice(index, 1);
            this.$forceUpdate();
        },

        initWeekDays() {
            this.weekDays.splice(0, this.weekDays.length);
            for(let i = 0; i < 7; i++) {
                const dayDate = addDays(this.currentStartWeekDay, i);
                const day = {
                    date: dayDate,
                    isMenuOpen: false,
                    newHours: '',
                    newMinutes: '',
                    disponibilities: []
                };
                this.weekDays.push(day);
                if (i > 0) {
                    this.weekDays[i - 1].nextDay = day;
                }
            }
        },

        goToPreviousWeek() {
            this.currentStartWeekDay = addWeeks(this.currentStartWeekDay, -1);
            this.currentEndOfWeekDay = addWeeks(this.currentEndOfWeekDay, -1);
            this.initWeekDays();
        },

        goToNextWeek() {
            this.currentStartWeekDay = addWeeks(this.currentStartWeekDay, 1);
            this.currentEndOfWeekDay = addWeeks(this.currentEndOfWeekDay, 1);
            this.initWeekDays();
        }
    },

    created() {
        this.initWeekDays();
    }
};
</script>
