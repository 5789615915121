var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Créer une disponibilité ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-text',[_c('ValidationObserver',{ref:"observer"},[_c('table',{staticStyle:{"border-spacing":"10px"}},[_c('tr',[_c('td',[_vm._v(" Quel intervenant ")]),_c('td',[_c('AppointmentStakeholderAutocomplete',{ref:"appointmentStakeholderAutocomplete",attrs:{"noDynamicSearch":""},model:{value:(_vm.stakeholder),callback:function ($$v) {_vm.stakeholder=$$v},expression:"stakeholder"}})],1)])])]),_c('div',{staticClass:"d-flex align-center justify-center mb-4"},[_c('v-btn',{staticClass:"mr-4",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.goToPreviousWeek()}}},[_c('v-icon',[_vm._v(" fas fa-chevron-left ")])],1),_c('span',{staticClass:"subtitle-1"},[_vm._v(" Semaine du "+_vm._s(_vm._f("toDate")(_vm.currentStartWeekDay))+" au "+_vm._s(_vm._f("toDate")(_vm.currentEndOfWeekDay))+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.goToNextWeek()}}},[_c('v-icon',[_vm._v(" fas fa-chevron-right ")])],1)],1),_c('table',{staticClass:"w-100"},[_c('thead',_vm._l((_vm.weekDays),function(day,i){return _c('th',{key:i,staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("toDay")(day.date))+" "),_c('br'),_c('span',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm._f("toDate")(day.date))+" ")])])}),0),_c('tbody',[_c('tr',_vm._l((_vm.weekDays),function(day,i){return _c('td',{key:i,staticClass:"text-center",staticStyle:{"width":"calc(100%/7)"}},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-plus ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",staticStyle:{"position":"absolute"},attrs:{"x-small":"","text":"","icon":""},on:{"click":function($event){return _vm.copyDisponibilitiesToNextDay(day)}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")])],1)]}}],null,true)},[_vm._v(" Copier sur le jour suivant ")])]}}],null,true),model:{value:(day.isMenuOpen),callback:function ($$v) {_vm.$set(day, "isMenuOpen", $$v)},expression:"day.isMenuOpen"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex"},[_c('v-select',{staticClass:"mr-2",staticStyle:{"width":"100px"},attrs:{"items":_vm.selectHours,"label":"de","hide-details":"","outlined":"","dense":""},model:{value:(day.startHours),callback:function ($$v) {_vm.$set(day, "startHours", $$v)},expression:"day.startHours"}}),_c('v-select',{staticStyle:{"width":"100px"},attrs:{"items":_vm.selectHours,"label":"à","hide-details":"","outlined":"","dense":""},model:{value:(day.endHours),callback:function ($$v) {_vm.$set(day, "endHours", $$v)},expression:"day.endHours"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","x-small":"","depressed":""},on:{"click":function($event){return _vm.addDisponibility(day, day.startHours, day.endHours)}}},[_vm._v(" Ajouter ")]),_c('v-btn',{attrs:{"x-small":"","depressed":""},on:{"click":function($event){return _vm.cancelDisponibility(day)}}},[_vm._v(" Annuler ")])],1)],1)],1)],1)}),0),_c('tr',_vm._l((_vm.weekDays),function(day,i){return _c('td',{key:i,staticClass:"text-center"},_vm._l((day.disponibilities),function(disponibility,i){return _c('div',{key:i,staticClass:"d-flex align-center justify-center"},[_vm._v(" De "+_vm._s(disponibility.startHours)+" à "+_vm._s(disponibility.endHours)+" "),_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.removeDisponibility(day, i)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-times ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":""},on:{"click":function($event){return _vm.copyDisponibilityToNextDay(disponibility, day)}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")])],1)]}}],null,true)},[_vm._v(" Copier sur le jour suivant ")])],1)}),0)}),0)])])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Enregistrer ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }